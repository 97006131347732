<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="移民人口自然变化及处理情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">县（市区）</div>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle leftTitletStyle">档案记录（人）</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">{{ searchObj.year }}年新增后扶人数（人）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">新核定</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">核增</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">核减</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">核查结果</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">核查结果与档案记录是否一致</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">是否按季度对县外迁入的已核定后扶移民进行核减</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">是否按季度对已核定后扶移民进行核减</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">是否按季度对核增核减人口进行批报</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle1">其他</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTitletStyle1">备注</div>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('postSupportCount') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('newDeterminationCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('increaseCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('decreaseCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle1"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle1"></div>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 220px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>
                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle">
                            <el-cascader  :append-to-body="false"  
                              v-if="type == 'add' || type == 'edit'"
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="true"
                              placeholder="请选择"
                            >
                            </el-cascader>
                            <div class="box_view" v-else>
                              {{ col['county'] }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'postSupportCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="text"
                              :maxlength="10"
                              @input="onInputFilter"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'newDeterminationCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="text"
                              :maxlength="10"
                              @input="onInputFilter"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'increaseCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="text"
                              :maxlength="10"
                              @input="onInputFilter"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'decreaseCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="text"
                              :maxlength="10"
                              @input="onInputFilter"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 检查结果 -->
                        <el-row v-if="key1 == 'isConsistent'">
                          <div class="contentStyle">
                            <div v-if="type == 'add' || type == 'edit'">
                              <el-radio v-model="col[key1]" :label="true">是</el-radio>
                              <el-radio v-model="col[key1]" :label="false">否</el-radio>
                            </div>
                            <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'isIncreased'">
                          <div class="contentStyle">
                            <div v-if="type == 'add' || type == 'edit'">
                              <el-radio v-model="col[key1]" :label="true">是</el-radio>
                              <el-radio v-model="col[key1]" :label="false">否</el-radio>
                            </div>
                            <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'isDecreased'">
                          <div class="contentStyle">
                            <div v-if="type == 'add' || type == 'edit'">
                              <el-radio v-model="col[key1]" :label="true">是</el-radio>
                              <el-radio v-model="col[key1]" :label="false">否</el-radio>
                            </div>
                            <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'isApproved'">
                          <div class="contentStyle">
                            <div v-if="type == 'add' || type == 'edit'">
                              <el-radio v-model="col[key1]" :label="true">是</el-radio>
                              <el-radio v-model="col[key1]" :label="false">否</el-radio>
                            </div>
                            <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                          </div>
                        </el-row>

                        <!-- 其他 -->
                        <el-row v-if="key1 == 'other'">
                          <div class="contentStyle1">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              type="textarea"
                              resize="none"
                              :rows="10"
                              placeholder="请输入"
                              v-model="col[key1]"
                            >
                            </el-input>
                            <div class="box_view" v-else>{{ 1111 }}</div>
                          </div>
                        </el-row>

                        <!-- 备注 -->
                        <el-row v-if="key1 == 'remarks'">
                          <div class="contentStyle1">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              type="textarea"
                              resize="none"
                              :rows="10"
                              placeholder="请输入"
                              v-model="col[key1]"
                            >
                            </el-input>
                            <div class="box_view" v-else>{{ 1111 }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _DemographicChangeAdd,
  _DemographicChangeInfo,
  _DemographicChangeEdit
} from '@/api/modular/postImmigrationService/monitorAndEvaluate/naturalChange.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      // treeProps: { label: 'name', value: 'id', children: 'childRegions', pid: 'pids', checkStrictly: true },
      treeProps: { checkStrictly: true, value: 'id', label: 'name', children: 'childRegions' },
      newarr: ['否', '是']
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 220 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
      this.listData[index].orderNo = obj.orderNo
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }

      return sum
    },
    onInputFilter(event) {
      const value = event.target.value
      const numericValue = value.replace(/[^\d]/g, '')

      if (numericValue === '' || Number.isInteger(parseFloat(numericValue))) {
        this.$set(this.col, key1, numericValue)
      } else {
        this.$nextTick(() => {
          event.target.value = this.col[key1] // 重置为上次合法值，避免显示非法输入
        })
      }
    },

    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },

    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },

    btnClose() {
      this.listData = []
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null,
            county: '',
            orderNo: null,
            countyPids: '',
            approvedTotal: null,
            postSupportCount: null,
            newDeterminationCount: null,
            increaseCount: null,
            decreaseCount: null,
            isConsistent: true,
            isIncreased: true,
            isDecreased: true,
            isApproved: true,
            other: '',
            remarks: ''
          }
          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let flag = this.listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _DemographicChangeAdd(this.listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          if (flag) {
            _DemographicChangeEdit(this.listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _DemographicChangeInfo(params).then(res => {
        if (res.code == 200) {
          console.log('===_DemographicChangeInfo====', res.data)
          this.listData = [res.data]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
